@media (max-width: 1024px) {
    .membership-grid-main .member-right-details {
        padding: 20px;
    }
}

@media (max-width: 767px) {
    .member-right-details h4 {
        font-size: 20px;
        line-height: 26px;
        font-weight: 300;
        margin-bottom: 15px;
    }

    .member-right-details p {
        line-height: 18px;
    }

    .member-right-details .membership-btns {
        margin-top: 20px;
        gap: 10px;
    }

    .member-right-details .membership-btns.main {
        flex-wrap: wrap;
    }
}

@media (max-width: 549px) {
    .member-right-details h4 {
        font-size: 18px;
        line-height: 24px;
        margin-bottom: 10px;
    }

    .member-right-details p {
        margin-bottom: 10px;
    }

    .membership-btns a {
        font-size: 12px;
        line-height: 14px;
        font-weight: 300;
    }

    .member-right-details .membership-btns img{
        width: 100px;
    }
}

@media (max-width: 374px) {
    .membership-grid-main .member-right-details {
        padding: 15px;
    }
}