@media (max-width: 1023px) {
    .main-contact .contact-experience-left {
        text-align: center;
    }

    .main-contact .contact-experience-left .owner-name {
        padding: 0;
    }

    .main-contact .contact-experience-left .owner-name::after {
        display: none;
    }

    .main-contact .contact-experience-right {
        justify-content: center;
    }
}

@media (max-width: 767px) {
    .main-contact .contact-experience {
        padding: 30px 0 200px;
    }

    .main-contact .contact-form-wrapper,
    .main-contact .contact-info {
        padding: 0 0 40px;
    }

    .main-contact .contact-info .contact-info-box {
        flex-direction: column;
        justify-content: center;
    }

    .main-contact .contact-info .contact-info-col {
        max-width: 100%;
    }

    .main-contact .contact-experience-left .title {
        font-size: 20px;
        line-height: 26px;
    }

    .main-contact .contact-experience-left .owner-name-box {
        margin-top: 10px;
    }

    .main-contact .contact-experience-left .owner-name {
        font-size: 16px;
        line-height: 22px;
    }

    .main-contact .contact-experience-left .owner-designation {
        font-weight: 300;
    }

    .main-contact .contact-experience-right .history-box-icon {
        font-size: 60px;
        line-height: 70px;
    }

    .main-contact .contact-experience-right .history-box-text {
        margin-top: 0;
        font-size: 14px;
        line-height: 20px;
        max-width: 200px;
    }

    .main-contact .contact-form .contact-form-title {
        font-size: 24px;
        line-height: 30px;
        margin-bottom: 5px;
    }

    .main-contact .contact-info .contact-info-title {
        font-size: 24px;
        line-height: 30px;
        margin-bottom: 15px;
        text-align: left;
    }

    .main-contact .contact-info .contact-info-col .contact-info-icon {
        min-width: 40px;
        min-height: 40px;
        max-width: 40px;
        max-height: 40px;
    }

    .main-contact .contact-info .contact-info-col .contact-info-icon svg {
        font-size: 20px;
    }
}

@media (max-width: 549px) {
    .main-contact .contact-form-box .contact-map iframe {
        height: auto;
    }

    .main-contact .contact-form-wrapper .contact-form-box {
        padding: 10px;
    }

    .main-contact .contact-form-wrapper,
    .main-contact .contact-info {
        padding: 0 0 30px;
    }

    .main-contact .contact-experience-left .title {
        font-size: 18px;
        line-height: 24px;
    }

    .main-contact .contact-experience-right .history-box-wrapper {
        gap: 20px;
    }

    .main-contact .contact-experience-right .history-box-icon {
        font-size: 50px;
        line-height: 60px;
    }

    .main-contact .contact-experience-right .history-box-text {
        font-size: 11px;
        line-height: 17px;
        max-width: 160px;
    }

    .main-contact .contact-experience {
        padding: 30px 0 150px;
    }

    .main-contact .contact-form-wrapper {
        margin-top: -100px;
    }

    .main-contact .contact-info .contact-info-col {
        gap: 10px;
    }

    .main-contact .contact-form .contact-form-title,
    .main-contact .contact-info .contact-info-title {
        font-size: 20px;
        line-height: 26px;
    }
    .main-contact .contact-info .contact-info-content{
        line-height: 18px;
        gap: 0;
    }
}