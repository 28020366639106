.app-about .main-about {
  background-color: #000;
}

.main-about .about-content {
  padding: 30px 0;
  max-width: 1000px;
  margin: 0 auto;
  color: #fff;
}

.main-about .about-content .about-content-left {
  text-align: center;
}

.main-about .about-content h3 {
  font-size: 30px;
  line-height: 36px;
  font-weight: 500;
  margin-bottom: 10px;
  text-transform: uppercase;
  color: #d3b15f;
}

.main-about .about-content p {
  font-size: 16px;
  line-height: 30px;
  font-weight: 300;
}

.main-about .about-feature {
  background-color: #000;
  padding: 60px 0;
}

.main-about .about-feature .cus-container {
  max-width: 1000px;
  margin: 0 auto;
}

.main-about .about-feature .heading .title {
  max-width: 550px;
  text-align: center;
  font-size: 30px;
  line-height: 36px;
  color: #d3b15f;
  margin: 0 auto;
  margin-bottom: 50px;
}

.main-about .about-feature-box-border {
  padding: 10px;
  background-color: #23211e;
  height: 100%;
}

.main-about .about-feature-box {
  padding: 50px 30px;
  border: 2px solid rgba(164, 172, 179, 0.4);
  height: 100%;
}

.main-about .about-feature-box .about-feature-icon {
  text-align: center;
  margin-bottom: 30px;
}

.main-about .about-feature-box .about-feature-icon img {
  height: 75px;
  width: auto;
}

.main-about .about-feature-box .about-feature-bottom {
  text-align: center;
  color: #fff;
}

.main-about .about-feature-box .about-feature-title {
  text-transform: uppercase;
  font-size: 18px;
  line-height: 24px;
  margin-bottom: 15px;
  color: #d3b15f;
}

.main-about .about-feature-box .about-feature-content {
  font-weight: 300;
}

.main-about .about-partner {
  padding: 60px 0;
}

.main-about .about-partner .partner-slider-img {
  background-color: #fff;
  border-radius: 10px;
  padding: 5px;
  display: flex;
}

.main-about .about-partner .heading .title {
  max-width: 550px;
  text-align: center;
  font-size: 30px;
  line-height: 36px;
  color: #d3b15f;
  margin: 0 auto;
  margin-bottom: 30px;
  text-transform: uppercase;
}

.main-about .about-content ul {
  padding-left: 15px;
}

.main-about .about-content ul li {
  list-style: disc;
}