.main-membership .membership-header {
    padding: 26px 0;
    color: #fff;
    border-bottom: 1px solid #23211e;
    text-align: center;
}

.main-membership .membership-header .membership-header-title {
    font-size: 30px;
    line-height: 36px;
    font-weight: 400;
    margin-bottom: 7px;
    color: #d3b15f;
}

.main-membership .membership-header .membership-header-subtitle {
    font-weight: 300;
}

.main-membership .membership-body{
    padding: 60px 0;
    max-width: 1000px;
    margin: 0 auto;
}

.main-membership .membership-body .membership-item-box{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 30px;
}

.main-membership .membership-body .membership-item{
    background-color: #0b0907;
    border-radius: 12px;
    padding: 30px;
    color: #fff;
    border: 2px solid #23211e;
    display: flex;
    flex-direction: column;
}

.main-membership .membership-body .membership-item.gold-membership{
    background-color: #211802;
    border: 2px solid #d3b15f;
    margin: -20px 0;
}

.main-membership .membership-body .membership-item .membership-title{
    color: #d3b15f;
    text-align: center;
    font-size: 20px;
    line-height: 26px;
    margin-bottom: 20px;
}

.main-membership .membership-body .membership-item .membership-price{
    text-align: center;
    margin-bottom: 20px;
}

.main-membership .membership-body .membership-item .membership-price-inner{
    border-radius: 100rem;
    display: inline-block;
    padding: 10px 30px;
    background: radial-gradient(ellipse farthest-corner at right bottom, #FEDB37 0%, #FDB931 8%, #9f7928 30%, #8A6E2F 40%, transparent 80%), radial-gradient(ellipse farthest-corner at left top, #fff 0%, #FDB931 8%, #9f7928 25%, #8A6E2F 62.5%, #5d4a1f 100%);
}

.main-membership .membership-body .membership-item .membership-content{
    font-weight: 300;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.5px;
    display: flex;
    align-items: flex-start;
    gap: 5px;
}

.main-membership .membership-body .membership-item .membership-content:not(:last-child){
    margin-bottom: 10px;
}

.main-membership .membership-body .membership-item .membership-content svg{
    font-size: 16px;
    margin-top: 2px;
}

.main-membership .membership-body .membership-item .membership-content.active svg{
    color: #d3b15f;
}

.main-membership .membership-body .membership-item .membership-content.disable,
.main-membership .membership-body .membership-item .membership-content.disable svg{
    color: #979797;
}

.main-membership .membership-body .membership-item .membership-btn-box{
    text-align: center;
    margin-top: auto;
}

.main-membership .membership-body .membership-item .membership-btn{
    margin-top: 20px;
}