.main-add-snacks .add-snacks-header {
    padding: 26px 0;
    color: #fff;
}

.main-add-snacks .add-snacks-header .header-title {
    font-size: 30px;
    line-height: 36px;
    font-weight: 400;
    margin-bottom: 7px;
    color: #d3b15f;
}

.main-add-snacks .add-snacks-header .header-content {
    font-size: 16px;
    line-height: 22px;
    font-weight: 300;
}

.add-snacks-tab-wrapper .add-snacks-tab-box {
    background-color: #0b0907;
}

.add-snacks-tab-wrapper .add-snacks-tab-box div[role="tablist"] {
    gap: 50px;
}

.add-snacks-tab-wrapper .add-snacks-tab-box .add-snacks-tab {
    padding: 25px 0;
    color: #fff;
    text-transform: capitalize;
    font-size: 16px;
    line-height: 22px;
    font-weight: 400;
}

.add-snacks-tab-wrapper .add-snacks-tab-box .add-snacks-tab.Mui-selected {
    color: #d3b15f;
}

.add-snacks-tab-wrapper .add-snacks-tab-box .MuiTabs-indicator {
    bottom: 10px;
    height: 3px;
    background: radial-gradient(ellipse farthest-corner at right bottom, #FEDB37 0%, #FDB931 8%, #9f7928 30%, #8A6E2F 40%, transparent 80%), radial-gradient(ellipse farthest-corner at left top, #fff 0%, #FDB931 8%, #9f7928 25%, #8A6E2F 62.5%, #5d4a1f 100%);
    border-radius: 100rem;
}

.add-snacks-tab-wrapper .add-snacks-tab-content>.MuiBox-root {
    padding: 0;
}

.add-snacks-body .add-snacks-content-box {
    padding: 30px 0;
    color: #fff;
}

.add-snacks-body .add-snacks-content-box .snacks-heading-title {
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 30px;
}

.add-snacks-body .add-snacks-content-box .snacks-bottom {
    display: flex;
    gap: 16px;
    align-items: flex-start;
}

.add-snacks-content-box .snacks-bottom-left {
    width: calc(70% - 8px);
}

.add-snacks-content-box .summary-box-wrapper {
    width: calc(30% - 8px);
}

.add-snacks-content-box .snacks-card-wrapper {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 16px;
}

.add-snacks-content-box .snacks-card {
    border-radius: 12px;
    overflow: hidden;
    background-color: #23211E;
    display: flex;
    flex-direction: column;
}

.add-snacks-content-box .snacks-card img {
    width: 100%;
    height: 100%;
    min-height: 173px;
    max-height: 173px;
    object-fit: cover;
    display: flex;
    background-color: #d3b15f08;
}

.add-snacks-content-box .snacks-card .snacks-card-content {
    padding: 10px;
    display: flex;
    flex-direction: column;
    height: 100%;
}

.add-snacks-content-box .snacks-card .snacks-card-content .snacks-card-desc {
    color: #BCBCBC;
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 5px;
}

.add-snacks-content-box .snacks-card .snacks-card-content .snacks-card-bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    margin-top: auto;
}

.add-snacks-content-box .snacks-card .snacks-card-content .snacks-card-price {
    font-size: 16px;
    line-height: 22px;
    color: #d3b15f;
}

.add-snacks-content-box .snacks-bottom .snacks-qty {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 60px;
    min-width: 60px;
    max-height: 21px;
    width: 100%;
    border-radius: 100rem;
    background-color: #181510;
    padding: 4px;
}

.add-snacks-content-box .snacks-bottom .snacks-qty button {
    padding: 3px;
    background-color: #d3b15f;
    border-radius: 50%;
    font-size: 12px;
    line-height: 0;
    font-weight: 500;
    color: #0b0907;
    min-width: 14px;
    min-height: 14px;
    max-width: 14px;
    max-height: 14px;
}

.add-snacks-content-box .snacks-bottom .snacks-qty .snacks-input {
    font-size: 12px;
    line-height: 18px;
    flex: 1;
    text-align: center;
    padding: 0 3px;
}

.add-snacks-body .summary-box-wrapper {
    background-color: #23211E;
    border-radius: 12px;
    padding: 20px 15px 30px;
    position: sticky;
    top: 85px;
}

.add-snacks-body .summary-box-wrapper .summary-box-inner {
    overflow-y: auto;
    overflow-x: hidden;
    max-height: calc(100vh - 200px);
    padding-right: 7.5px;
    margin-right: -7.5px;
}

.add-snacks-body .summary-box-wrapper .summary-main-title {
    font-size: 18px;
    line-height: 21px;
    border-bottom: 1px solid #0b0907;
    text-align: center;
    padding-bottom: 20px;
    margin-bottom: 20px;
}

.add-snacks-body .summary-box-wrapper svg {
    width: 16px;
    height: 16px;
    margin-top: 2px;
    color: #d3b15f;
}

.add-snacks-body .summary-box-wrapper .summary-box-row {
    display: flex;
    gap: 10px;
    font-size: 16px;
    line-height: 19px;
}

.add-snacks-body .summary-box-wrapper .payment-summary-row {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    font-size: 16px;
    line-height: 19px;
}

.add-snacks-body .summary-box-wrapper .payment-summary-row .payment-summary-label {
    font-size: 12px;
    line-height: 14px;
    color: #BCBCBC;
}

.add-snacks-body .summary-box-wrapper .summary-box-row:not(:last-child),
.add-snacks-body .summary-box-wrapper .payment-summary-row:not(:last-child) {
    margin-bottom: 15px;
}

.add-snacks-body .summary-box-wrapper .booking-summary,
.add-snacks-body .summary-box-wrapper .payment-summary {
    border-bottom: 1px solid #0b0907;
    padding-bottom: 20px;
    margin-bottom: 20px;
}

.add-snacks-body .summary-box-wrapper .selected-movie .summary-title {
    color: #d3b15f;
    text-transform: uppercase;
}

.add-snacks-body .summary-box-wrapper .selected-cinema div {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.add-snacks-body .summary-box-wrapper .selected-movie div {
    display: flex;
    align-items: center;
    gap: 5px;
}

.add-snacks-body .summary-box-wrapper .selected-movie img {
    width: 50px;
    border-radius: 6px;
    aspect-ratio: 3/4.5;
    object-fit: cover;
}

.add-snacks-body .summary-box-wrapper .selected-cinema .summary-subtitle {
    color: #BCBCBC;
    font-size: 12px;
    line-height: 14px;
}

.add-snacks-body .summary-box-wrapper .selected-movie .summary-subtitle {
    color: #bcbcbc;
    font-size: 10px;
    line-height: 19px;
}

.add-snacks-body .summary-box-wrapper .selected-snacks-box {
    margin-bottom: 15px;
}

.add-snacks-body .summary-box-wrapper .selected-snacks-box .MuiAccordion-root {
    width: 100%;
    background-color: transparent;
    box-shadow: none;
    color: #fff;
    font-size: 16px;
    line-height: 19px;
}

.add-snacks-body .summary-box-wrapper .selected-snacks-box .MuiAccordionSummary-root {
    min-height: unset;
    padding: 0;
    flex-direction: row-reverse;
    gap: 10px;
    margin-bottom: 15px;
}

.add-snacks-body .summary-box-wrapper .selected-snacks-box .MuiAccordionSummary-content {
    margin: 0;
}

.add-snacks-body .summary-box-wrapper .selected-snacks-box .MuiAccordionSummary-expandIconWrapper {
    border: 1px solid #d3b15f;
    border-radius: 50%;
    color: #d3b15f;
}

.add-snacks-body .summary-box-wrapper .selected-snacks-box .MuiAccordionSummary-expandIconWrapper svg {
    margin: 0;
}

.add-snacks-body .summary-box-wrapper .selected-snacks-box .MuiAccordionDetails-root {
    padding: 0;
}

.add-snacks-body .summary-box-wrapper .selected-snacks {
    background-color: #000;
    padding: 10px;
    border-radius: 12px;
}

.add-snacks-body .summary-box-wrapper .selected-snacks img {
    width: 100%;
    max-width: 109px;
    border-radius: 6px;
    overflow: hidden;
    object-fit: cover;
}

.add-snacks-body .summary-box-wrapper .selected-snacks .snacks-summary {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.add-snacks-body .summary-box-wrapper .selected-snacks .snacks-desc {
    font-size: 12px;
    line-height: 14px;
    color: #BCBCBC;
    margin-bottom: 8px;
}

.add-snacks-body .summary-box-wrapper .selected-snacks .snacks-price {
    font-weight: 500;
    color: #d3b15f;
    margin-bottom: 8px;
}

.add-snacks-body .summary-box-wrapper .selected-snacks .selected-snacks-bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 15px;
    margin-top: auto;
}

.add-snacks-body .summary-box-wrapper .selected-snacks .snacks-qty {
    background-color: #23211E;
}

.add-snacks-body .summary-box-wrapper .selected-snacks .snacks-remove {
    text-decoration: underline;
    font-weight: 300;
    color: #4E4C41;
    font-size: 12px;
    line-height: 14px;
    cursor: pointer;
    transition: 0.2s all ease-in-out;
    text-transform: capitalize;
    padding: 0;
    min-width: unset;
}

.add-snacks-body .summary-box-wrapper .selected-snacks .snacks-remove:hover {
    color: #fff;
}

.add-snacks-body .summary-box-wrapper .apply-promocode-box {
    position: relative;
}

.add-snacks-body .summary-box-wrapper .apply-promocode-box fieldset {
    border: 0;
}

.add-snacks-body .summary-box-wrapper .apply-promocode-box input {
    border: 1px solid #d3b15f;
    border-radius: 100rem;
    width: 100%;
    font-size: 12px;
    line-height: 14px;
    font-weight: 400;
    color: #d3b15f;
    padding: 10px 95px 10px 15px;
}

.add-snacks-body .summary-box-wrapper .apply-promocode-box input::placeholder {
    opacity: 1;
}

.add-snacks-body .summary-box-wrapper .apply-promocode-box button {
    position: absolute;
    right: 5px;
    top: 50%;
    transform: translateY(-50%);
    padding: 8px 16px;
}

.add-snacks-body .summary-box-wrapper .total-payment-row {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    margin-bottom: 20px;
    font-size: 20px;
    line-height: 24px;
    color: #d3b15f;
}

.add-snacks-body .summary-box-wrapper .summary-box-button .book-now-btn {
    width: 100%;
}

.add-snacks-body .summary-box-wrapper .summary-box-button .add-snacks-btn {
    width: 100%;
    margin-top: 20px;
}