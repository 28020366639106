.main-movie-detail .movie-detail-banner {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
}

.main-movie-detail .movie-detail-banner::after {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: transparent linear-gradient(271deg, #100d0d 0%, #4d3f04 20%, #110e01 64%, #000000 100%) 0% 0% no-repeat padding-box;
  opacity: 0.89;
}

.movie-detail-banner .detail-banner-box {
  padding: 30px 0;
  position: relative;
  z-index: 1;
  display: flex;
  gap: 30px;
  align-items: center;
  color: #fff;
}

.movie-detail-banner .detail-banner-box .detail-banner-share {
  margin-left: auto;
  align-self: flex-start;
}

.movie-detail-banner .detail-banner-box .detail-banner-share button {
  min-width: unset;
  padding: 5px;
}

.movie-detail-banner .detail-banner-img {
  width: auto;
  max-width: 260px;
  position: relative;
  display: flex;
}

.movie-detail-banner .detail-banner-img .detail-banner-img-tag {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.8);
  padding: 8px;
  left: 0;
  bottom: 0;
  width: 100%;
  text-align: center;
  font-weight: 400;
}

.movie-detail-banner .detail-banner-img img {
  width: 100%;
  aspect-ratio: 3/4.5;
  height: 390px;
  object-fit: cover;
}

.movie-detail-banner .detail-banner-content .detail-banner-title {
  font-size: 30px;
  line-height: 36px;
  font-weight: 500;
  margin-bottom: 15px;
}

.movie-detail-banner .detail-banner-content .detail-banner-like {
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 16px;
  line-height: 22px;
  margin-bottom: 20px;
}

.movie-detail-banner .detail-banner-content .detail-banner-like .like-icon {
  color: #2e7d32;
  cursor: pointer;
}

.movie-detail-banner .detail-banner-content .detail-banner-like .star-icon {
  color: #fecc00;
}

.movie-detail-banner .detail-banner-interest {
  padding: 11px 20px 14px 13px;
  background: #19151080;
  border: 1px solid #d3b15f;
  display: flex;
  align-items: center;
  gap: 25px;
  margin-bottom: 20px;
  max-width: fit-content;
}

.movie-detail-banner .detail-banner-interest .banner-interest-button {
  flex: 1;
}

.movie-detail-banner .detail-banner-interest .banner-interest-title {
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 5px;
}

.movie-detail-banner .detail-banner-interest .banner-interest-subtitle {
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
}

.movie-detail-banner .detail-banner-content .banner-interest-tags {
  margin: 20px 0 25px;
  display: flex;
  align-items: center;
  gap: 10px;
  line-height: 1;
}

.movie-detail-banner .detail-banner-content .banner-interest-tag {
  padding: 8px 16px;
  min-width: 100px;
  border-radius: 100rem;
  background: #fffeab00 0% 0% no-repeat padding-box;
  border: 1px solid #d3b15f;
  display: inline-block;
  text-align: center;
}

.movie-detail-banner .detail-banner-content .book-now-box{
  display: flex;
  align-items: center;
  gap: 15px;
}

.movie-detail-banner .detail-banner-content .play-trailer-btn {
  padding: 8px;
  min-width: unset;
}

.movie-detail-tab-wrapper .movie-detail-tab-box {
  background-color: #0b0907;
}

.movie-detail-tab-wrapper .movie-detail-tab-box div[role="tablist"] {
  gap: 50px;
}

.movie-detail-tab-wrapper .movie-detail-tab-box .movie-detail-tab {
  padding: 25px 0;
  color: #fff;
  text-transform: capitalize;
  font-size: 16px;
  line-height: 22px;
  font-weight: 400;
  min-width: unset;
}

.movie-detail-tab-wrapper .movie-detail-tab-box .movie-detail-tab.Mui-selected {
  color: #d3b15f;
}

.movie-detail-tab-wrapper .movie-detail-tab-box .MuiTabs-indicator {
  bottom: 10px;
  height: 3px;
  background: radial-gradient(ellipse farthest-corner at right bottom,
      #fedb37 0%,
      #fdb931 8%,
      #9f7928 30%,
      #8a6e2f 40%,
      transparent 80%),
    radial-gradient(ellipse farthest-corner at left top,
      #fff 0%,
      #fdb931 8%,
      #9f7928 25%,
      #8a6e2f 62.5%,
      #5d4a1f 100%);
  border-radius: 100rem;
}

.movie-detail-tab-wrapper .movie-detail-tab-content {
  background-color: #000;
}

.movie-detail-tab-wrapper .movie-detail-tab-content>.MuiBox-root {
  padding: 0;
}

.movie-detail-tab-wrapper .detail-tab-content,
.movie-detail-tab-wrapper .about-tab-content,
.movie-detail-tab-wrapper .review-tab-content {
  padding-bottom: 30px;
  color: #fff;
}

.movie-detail-tab-wrapper .detail-tab-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 15px;
  padding: 20px 0;
  border-bottom: 1px solid #000;
}

.movie-detail-tab-wrapper .detail-tab-header .detail-tab-date {
  width: 100%;
  max-width: 470px;
  padding: 0 32px;
  position: relative;
}

.movie-detail-tab-wrapper .detail-tab-header .detail-tab-date .date-item.swiper-slide {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2px;
  padding: 8px 16px;
  border-radius: 6px;
  cursor: pointer;
  transition: 0.2s all ease;
  border: 1px solid #23211e;
}

.movie-detail-tab-wrapper .detail-tab-header .detail-tab-date .date-item.swiper-slide.slide-active {
  color: #d3b15f;
  border: 1px solid #d3b15f;
}

.movie-detail-tab-wrapper .detail-tab-header .detail-tab-date .date-item.swiper-slide:not(.slide-active):hover {
  color: #d3b15f;
}

.movie-detail-tab-wrapper .detail-tab-header .detail-tab-date .swiper-button-prev {
  left: 0;
}

.movie-detail-tab-wrapper .detail-tab-header .detail-tab-date .swiper-button-next {
  right: 0;
}

.movie-detail-tab-wrapper .detail-tab-header .detail-tab-date .swiper-button-prev,
.movie-detail-tab-wrapper .detail-tab-header .detail-tab-date .swiper-button-next {
  background: #4e4c41;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  top: 50%;
  transform: translateY(-50%);
  margin-top: 0;
  z-index: 2;
}

.movie-detail-tab-wrapper .detail-tab-header .detail-tab-date .swiper-button-prev:after,
.movie-detail-tab-wrapper .detail-tab-header .detail-tab-date .swiper-button-next:after {
  font-size: 12px;
  font-weight: bolder;
  color: #191510;
}

.movie-detail-tab-wrapper .detail-tab-header .detail-tab-date .day,
.movie-detail-tab-wrapper .detail-tab-header .detail-tab-date .month {
  font-size: 12px;
  line-height: 14px;
  font-weight: 400;
  text-transform: uppercase;
}

.movie-detail-tab-wrapper .detail-tab-header .detail-tab-date .date {
  font-size: 16px;
  line-height: 19px;
}

.movie-detail-tab-wrapper .detail-tab-header .detail-tab-filter {
  display: flex;
  align-items: stretch;
  justify-content: flex-end;
  gap: 15px;
  flex: 1;
}

.filter-dropdown-main.language-category-dropdown,
.filter-dropdown-main.price-range-dropdown {
  width: 100%;
  max-width: 130px;
}

.filter-dropdown-main.show-time-dropdown {
  width: 100%;
  max-width: 205px;
}

.movie-detail-tab-wrapper .detail-tab-body .detail-tab-body-top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 0;
}

.movie-detail-tab-wrapper .detail-tab-body .detail-tab-search {
  position: relative;
  width: 100%;
  max-width: 540px;
  flex: 1;
}

.movie-detail-tab-wrapper .detail-tab-body .detail-tab-search svg {
  position: absolute;
  left: 12px;
  top: 50%;
  transform: translateY(-50%);
  color: #d3b15f;
  z-index: 1;
}

.movie-detail-tab-wrapper .detail-tab-body .detail-tab-search input {
  width: 100%;
  background-color: #23211e;
  border-radius: 100rem;
  font-size: 12px;
  line-height: 14px;
  font-weight: 400;
  color: #d3b15f;
  padding: 12px 12px 12px 40px;
}

.movie-detail-tab-wrapper .detail-tab-body .detail-tab-search input::placeholder {
  opacity: 1;
}

.movie-detail-tab-wrapper .detail-tab-body .detail-tab-search fieldset {
  border: 0;
}

.movie-detail-tab-wrapper .detail-tab-body .detail-tab-availability span {
  padding-left: 17px;
  margin-left: 35px;
  font-size: 11px;
  line-height: 13px;
  font-weight: 400;
  text-transform: uppercase;
  display: inline-block;
  position: relative;
}

.movie-detail-tab-wrapper .detail-tab-body .detail-tab-availability span::before {
  position: absolute;
  content: "";
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: currentColor;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}

.movie-detail-tab-wrapper .detail-tab-body .detail-tab-availability .fast-filling {
  color: #f3891e;
}

.movie-detail-tab-wrapper .detail-tab-body .detail-tab-body-bottom {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.movie-detail-tab-wrapper .detail-tab-body .theatre-shows-box {
  background: #23211e;
  border-radius: 12px;
  padding: 24px 48px 24px 24px;
  display: flex;
  justify-content: space-between;
  gap: 30px;
}

.movie-detail-tab-wrapper .detail-tab-body .theatre-shows-left {
  width: 100%;
  max-width: 380px;
}

.movie-detail-tab-wrapper .detail-tab-body .theatre-shows-right {
  width: 100%;
}

.movie-detail-tab-wrapper .detail-tab-body .theatre-shows-box .theatre-name {
  font-size: 18px;
  line-height: 24px;
  font-weight: 400;
  margin-bottom: 10px;
}

.movie-detail-tab-wrapper .detail-tab-body .theatre-shows-box .theatre-info {
  font-size: 10px;
  line-height: 11px;
  font-weight: 400;
  display: flex;
  align-items: center;
  gap: 10px;
}

.movie-detail-tab-wrapper .detail-tab-body .theatre-shows-box .movie-cancellation {
  color: #d3b15f;
  padding-left: 12px;
  position: relative;
}

.movie-detail-tab-wrapper .detail-tab-body .theatre-shows-box .movie-cancellation::before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 7px;
  height: 7px;
  background-color: #d3b15f;
  border-radius: 50%;
}

.movie-detail-tab-wrapper .detail-tab-body .theatre-shows-box .movie-info {
  display: flex;
  align-items: center;
  gap: 3px;
  cursor: pointer;
  position: relative;
}

.movie-detail-tab-wrapper .detail-tab-body .theatre-shows-box .movie-info svg.info {
  font-size: 16px;
  line-height: 1;
}

.movie-detail-tab-wrapper .detail-tab-body .movie-info .movie-info-tooltip {
  position: absolute;
  background-color: #ffffff;
  z-index: 1;
  color: #000;
  width: 100%;
  min-width: max-content;
  gap: 10px;
  padding: 10px;
  border-radius: 6px;
  bottom: calc(100% + 10px);
  left: -100%;
  display: flex;
  justify-content: center;
  text-align: center;
  opacity: 0;
  visibility: hidden;
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2),
    0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.movie-detail-tab-wrapper .detail-tab-body .movie-info .movie-info-tooltip::after {
  content: "";
  position: absolute;
  display: block;
  width: 0px;
  left: 17%;
  top: 100%;
  border: 5px solid transparent;
  border-bottom: 0;
  border-top: 5px solid #fff;
}

.movie-detail-tab-wrapper .detail-tab-body .movie-info:hover .movie-info-tooltip {
  opacity: 1;
  visibility: visible;
}

.movie-detail-tab-wrapper .detail-tab-body .movie-info .movie-info-tooltip-inner {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
  max-width: 65px;
  min-width: 45px;
}

.movie-detail-tab-wrapper .detail-tab-body .movie-info .movie-info-tooltip-inner img {
  min-width: 24px;
  min-height: 24px;
}

.movie-detail-tab-wrapper .detail-tab-body .theatre-shows-box .theatre-location {
  display: flex;
  gap: 7px;
  font-size: 12px;
  line-height: 18px;
  font-weight: 400;
  color: #bcbcbc;
  max-width: 350px;
  margin-top: 10px;
}

.movie-detail-tab-wrapper .detail-tab-body .theatre-shows-box .theatre-location svg {
  font-size: 14px;
  color: #d3b15f;
  margin-top: 2px;
}

.movie-detail-tab-wrapper .detail-tab-body .theatre-shows-box .movie-lang {
  display: inline-block;
  color: #d3b15f;
  margin-bottom: 15px;
}

.movie-detail-tab-wrapper .detail-tab-body .theatre-shows-box .movie-timing-box {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 16px;
}

.movie-detail-tab-wrapper .detail-tab-body .theatre-shows-box .movie-timing {
  padding: 5px 20px;
  font-size: 12px;
  line-height: 14px;
  font-weight: 400;
  border-radius: 12px;
  border: 1px solid #808080;
  color: #fff;
  position: relative;
  display: inline-block;
  cursor: pointer;
  background-color: transparent;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: inherit;
  min-height: 40px;
}

.movie-detail-tab-wrapper .detail-tab-body .theatre-shows-box .movie-timing-label {
  font-size: 10px;
  line-height: 14px;
  font-weight: 300;
  text-transform: uppercase;
}

.movie-detail-tab-wrapper .detail-tab-body .theatre-shows-box .almost-full.movie-timing {
  color: #f3891e;
}

.movie-detail-tab-wrapper .detail-tab-body .theatre-shows-box .sold-out.movie-timing {
  color: #808080;
}

.movie-detail-tab-wrapper .detail-tab-body .movie-timing-tooltip .timing-tooltip-price {
  font-size: 14px;
  line-height: 20px;
}

.movie-detail-tab-wrapper .detail-tab-body .movie-timing-tooltip .timing-tooltip-class {
  font-size: 10px;
  line-height: 16px;
}

.movie-detail-tab-wrapper .detail-tab-body .movie-timing-tooltip .timing-tooltip-availability {
  font-weight: 500;
}

.movie-detail-tab-wrapper .detail-tab-body .movie-timing-tooltip .timing-tooltip-availability.available {
  color: #4abd5d;
}

.movie-detail-tab-wrapper .detail-tab-body .movie-timing-tooltip .timing-tooltip-availability.filling-fast {
  color: #f3891e;
}

.movie-detail-tab-wrapper .detail-tab-body .movie-timing-tooltip .timing-tooltip-availability.sold-out {
  color: #808080;
}

.movie-detail-tab-wrapper .detail-tab-body .movie-timing .movie-timing-tooltip {
  position: absolute;
  background-color: #ffffff;
  z-index: 1;
  color: #000;
  width: 100%;
  min-width: max-content;
  gap: 15px;
  padding: 10px 15px;
  border-radius: 6px;
  bottom: calc(100% + 10px);
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: center;
  text-align: center;
  pointer-events: none;
  opacity: 0;
  visibility: hidden;
  transition: 0.25s all ease-in-out;
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2),
    0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.movie-detail-tab-wrapper .detail-tab-body .movie-timing .movie-timing-tooltip::after {
  content: "";
  position: absolute;
  display: block;
  width: 0px;
  left: 50%;
  bottom: 0;
  border: 5px solid transparent;
  border-bottom: 0;
  border-top: 5px solid #fff;
  transform: translate(-50%, 100%);
}

.movie-detail-tab-wrapper .detail-tab-body .movie-timing:hover .movie-timing-tooltip {
  opacity: 1;
  visibility: visible;
}

.movie-detail-tab-wrapper .about-tab-header {
  padding: 30px 0;
}

.movie-detail-tab-wrapper .about-tab-header .tab-header-title,
.movie-detail-tab-wrapper .about-tab-body .cast-crew-title {
  font-size: 20px;
  line-height: 24px;
  margin-bottom: 15px;
}

.movie-detail-tab-wrapper .about-tab-header .tab-header-content {
  font-size: 14px;
  line-height: 21px;
  font-weight: 400;
  color: #bcbcbc;
}

.movie-detail-tab-wrapper .about-tab-body .about-tab-cast-crew {
  margin-bottom: 20px;
}

.movie-detail-tab-wrapper .about-tab-body .cast-crew-wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
  font-weight: 300;
}

.movie-detail-tab-wrapper .about-tab-body .cast-crew-item {
  text-align: center;
  display: inline-block;
  max-width: 108px;
}

.movie-detail-tab-wrapper .about-tab-body .cast-crew-item .cast-crew-img {
  padding: 5px;
  display: inline-flex;
  position: relative;
  margin-bottom: 8px;
}

.movie-detail-tab-wrapper .about-tab-body .cast-crew-item .cast-crew-img::after {
  position: absolute;
  content: "";
  width: 106px;
  height: 106px;
  left: 0;
  top: 0;
  border: 1px solid #d3b15f;
  border-radius: 50%;
}

.movie-detail-tab-wrapper .about-tab-body .cast-crew-item img {
  width: 98px;
  height: 98px;
  border-radius: 50%;
  object-fit: cover;
}

.movie-detail-tab-wrapper .about-tab-body .cast-crew-item .real-name {
  font-weight: 300;
}

.movie-detail-tab-wrapper .about-tab-body .cast-crew-item .movie-name {
  color: #d3b15f;
  font-size: 12px;
  line-height: 16px;
}

.movie-detail-tab-wrapper .review-tab-content .no-found-img-box {
  margin-top: 30px;
}

.review-content-main {
  padding-top: 30px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 15px;
}

.review-content-inner-main {
  background: #23211e;
  border-radius: 12px;
  padding: 20px;
  display: flex;
  flex-direction: column;
}

.review-user-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 15px;
  margin-bottom: 15px;
}

.review-user-profile-flex {
  display: flex;
  align-items: center;
  gap: 10px;
}

.review-user-profile-flex .review-user-img {
  min-height: 30px;
  min-width: 30px;
  max-height: 30px;
  max-width: 30px;
  border-radius: 50%;
  object-fit: cover;
}

.review-user-profile-flex .review-user-name {
  font-weight: 300;
}

.review-star-flex {
  display: flex;
  align-items: center;
  gap: 5px;
}

.review-star-flex .review-total-text-main {
  line-height: 1;
  font-weight: 300;
  letter-spacing: 2px;
}

.review-star-flex .review-star-fill {
  fill: #fedb37;
  font-size: 20px;
}

.review-content-main .review-para {
  margin-bottom: 15px;
  font-weight: 300;
}

.review-content-inner-main .review-date-text {
  font-size: 12px;
  line-height: 1;
  color: #bcbcbc;
  margin-top: auto;
}