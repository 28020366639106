.main-cinema .cinema-header {
  text-align: center;
}

.main-cinema .cinema-header,
.main-cinema-inner .cinema-header {
  padding: 26px 0;
  color: #fff;
  border-bottom: 1px solid #23211e;
}

.main-cinema .cinema-header .cinema-header-title,
.main-cinema-inner .cinema-header .cinema-header-title {
  font-size: 30px;
  line-height: 36px;
  font-weight: 400;
  margin-bottom: 7px;
  color: #d3b15f;
}

.main-cinema .cinema-header .cinema-header-subtitle {
  font-weight: 300;
}

.movie-detail-tab-wrapper .detail-tab-body .theatre-shows-box .theatre-location {
  display: flex;
  gap: 7px;
  font-size: 12px;
  line-height: 18px;
  font-weight: 400;
  color: #bcbcbc;
  max-width: 350px;
  margin-bottom: 10px;
}

.main-cinema .no-cinema-body {
  padding: 30px 0;
}

.main-cinema .cinema-body {
  padding: 30px 0;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 30px;
}

.main-cinema .cinema-body {
  padding: 30px 0;
}

.main-cinema .cinema-body .cinema-box {
  position: relative;
  display: flex;
  overflow: hidden;
}

.main-cinema .cinema-body .cinema-box .cinema-box-link {
  position: absolute;
  width: 100%;
  height: 100%;
}

.main-cinema .cinema-body .cinema-box img {
  height: 300px;
  max-height: 300px;
  object-fit: cover;
  width: 100%;
}

.main-cinema .cinema-body .cinema-box .cinema-box-content {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  transition: 0.5s all ease-out;
  height: 25%;
  background-color: rgba(0, 0, 0, 0.8);
  color: #fff;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 15px;
}

.main-cinema .cinema-body .cinema-box .cinema-box-content .cinema-title {
  color: #d3b15f;
  font-size: 20px;
  line-height: 24px;
  font-weight: 500;
}

.main-cinema .cinema-body .cinema-box .cinema-box-content .cinema-city {
  font-weight: 300;
  margin-top: 5px;
  text-transform: uppercase;
}

.main-cinema .cinema-body .cinema-box .cinema-box-content .cinema-address {
  height: 0;
  opacity: 0;
  transition: 0.5s all ease-in-out;
  font-weight: 300;
}

.main-cinema .cinema-body .cinema-box:hover .cinema-box-content {
  height: 100%;
}

.main-cinema .cinema-body .cinema-box:hover .cinema-box-content .cinema-address {
  opacity: 1;
  height: auto;
  margin-top: 10px;
}

.main-cinema-inner .cinema-header .theatre-location {
  display: flex;
  gap: 7px;
  color: #bcbcbc;
}

.main-cinema-inner .cinema-header .theatre-location svg {
  font-size: 20px;
  color: #d3b15f;
}

.main-cinema-inner .movie-detail-tab-wrapper .detail-tab-body .theatre-shows-box .theatre-location svg {
  display: none;
}

.main-cinema-inner .cinema-inner-slider {
  width: 100%;
  height: 320px;
  min-height: 280px;
  margin-bottom: 20px;
}

.main-cinema-inner .cinema-inner-slider .swiper,
.main-cinema-inner .cinema-inner-slider .swiper .cinema-inner-slider-img {
  width: 100%;
  height: 100%;
}

.main-cinema-inner .cinema-inner-slider img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  border-radius: 6px;
}

.main-cinema-inner .cinema-inner-slider .swiper .swiper-button-prev,
.main-cinema-inner .cinema-inner-slider .swiper .swiper-button-next {
  background: #191510;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  top: 50%;
  transform: translateY(-50%);
  margin-top: 0;
  z-index: 2;
}

.main-cinema-inner .cinema-inner-slider .swiper .swiper-button-prev::after,
.main-cinema-inner .cinema-inner-slider .swiper .swiper-button-next::after {
  font-size: 12px;
  font-weight: bolder;
  color: #d3b15f;
}