@media (max-width: 1023px) {
  .main-about .about-content h3 {
    font-size: 24px;
    line-height: 30px;
    margin-bottom: 5px;
  }

  .main-about .about-content p {
    line-height: 24px;
  }

  .main-about .about-feature,
  .main-about .about-partner {
    padding: 40px 0;
  }

  .main-about .about-feature .heading .title {
    font-size: 24px;
    line-height: 30px;
    margin-bottom: 30px;
    max-width: 400px;
  }

  .main-about .about-feature-box {
    padding: 30px 15px;
  }

  .main-about .about-partner .heading .title {
    font-size: 24px;
    line-height: 30px;
    margin-bottom: 20px;
  }
}

@media (max-width: 767px) {

  .main-about .about-feature,
  .main-about .about-partner {
    padding: 30px 0;
  }

  .main-about .about-content h3 {
    font-size: 18px;
    line-height: 24px;
  }

  .main-about .about-content p {
    font-size: 14px;
    line-height: 22px;
  }

  .main-about .about-feature .heading .title {
    font-size: 20px;
    line-height: 26px;
    margin-bottom: 20px;
  }

  .main-about .about-partner .heading .title {
    font-size: 20px;
    line-height: 26px;
  }

  .main-about .about-partner .partner-slider-img {
    border-radius: 6px;
  }
}

@media (max-width: 549px) {
  .main-about .about-feature .heading .title {
    font-size: 18px;
    line-height: 24px;
  }

  .main-about .about-feature-box .about-feature-icon img {
    height: 50px;
  }

  .main-about .about-feature-box .about-feature-icon {
    margin-bottom: 10px;
  }

  .main-about .about-feature-box .about-feature-title {
    font-size: 16px;
    line-height: 22px;
    margin-bottom: 10px;
  }

  .main-about .about-partner .heading .title {
    font-size: 18px;
    line-height: 1;
    margin-bottom: 15px;
  }
}