.main-account .no-data-found {
  color: #fff;
  font-size: 16px;
  line-height: 22px;
  font-weight: 300;
}

.main-account .account-tab-heading-box {
  margin-bottom: 20px;
}

.main-account .account-tab-heading-box .account-tab-heading {
  color: #d3b15f;
  font-size: 24px;
  line-height: 30px;
  font-weight: 500;
}

.main-account .account-header {
  padding-top: 40px;
}

.main-account .account-body {
  display: flex;
  gap: 30px;
  align-items: flex-start;
}

.main-account .account-body .account-tab-box {
  max-width: 280px;
  width: 100%;
  padding-top: 40px;
  margin: 30px 0;
  position: relative;
}

.main-account .account-body .account-tab-box .account-tab-box-inner {
  padding: 60px 0 30px;
  background-color: #23211e;
  border-radius: 12px;
}

.main-account .account-body .account-tab-box .profile-img-box {
  position: absolute;
  z-index: 1;
  left: 50%;
  transform: translateX(-50%);
  top: 0;
}

.main-account .account-body .account-tab-box .profile-img-box .profile-img-title {
  display: none;
}

.main-account .account-body .account-tab-box .profile-img {
  display: flex;
  border: 2px solid #d3b15f;
  background-color: #000;
  border-radius: 50%;
}

.main-account .account-body .account-tab-box .profile-img img {
  max-width: 80px;
  max-height: 80px;
  min-width: 80px;
  min-height: 80px;
  border-radius: 50%;
  object-fit: cover;
}

.main-account .account-body .account-tab-box .profile-img-title {
  color: #fff;
  text-align: center;
  font-size: 16px;
  line-height: 22px;
  text-transform: uppercase;
  margin-bottom: 20px;
  word-break: break-all;
  padding: 0 15px;
}

.main-account .account-body .account-tab-box .account-tab {
  padding: 20px 25px;
  align-items: center;
  justify-content: flex-start;
  gap: 7px;
  flex-direction: row;
  color: #fff;
  text-transform: capitalize;
  font-size: 16px;
  line-height: 22px;
  font-weight: 300;
  transition: 0.25s all ease-in-out;
}

.main-account .account-body .account-tab-box .account-tab svg {
  font-size: 19px;
}

.main-account .account-body .account-tab-box .Mui-disabled.MuiTabs-scrollButtons {
  display: none;
}

.main-account .account-body .account-tab-box .account-tab.Mui-selected {
  color: #d3b15f;
  background-color: #130d00;
  font-weight: 400;
}

.main-account .account-body .account-tab-box .MuiTabs-indicator {
  width: 5px;
  border-radius: 0 5px 5px 0;
  left: 0;
  background: radial-gradient(ellipse farthest-corner at right bottom,
      #fedb37 0%,
      #fdb931 8%,
      #9f7928 30%,
      #8a6e2f 40%,
      transparent 80%),
    radial-gradient(ellipse farthest-corner at left top,
      #fff 0%,
      #fdb931 8%,
      #9f7928 25%,
      #8a6e2f 62.5%,
      #5d4a1f 100%);
}

.main-account .account-body .account-tab-wrapper {
  width: 100%;
  padding: 30px;
  margin: 70px 0 30px;
  background-color: #23211e;
  border-radius: 12px;
}

.main-account .account-tab-wrapper .account-tab-content>.MuiBox-root {
  padding: 0;
}

.main-account .account-body .account-tab-booking {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.main-account .account-body .your-booking-card {
  background-color: #130d00;
  padding: 15px;
  box-shadow: 0 4px 54px 10px #0000001a;
  display: flex;
  gap: 15px;
  width: calc(50% - 5px);
  border-radius: 12px;
  position: relative;
  overflow: hidden;
}

.main-account .your-booking-card .booking-card-summary {
  width: 100%;
}

.main-account .your-booking-card img {
  width: 100%;
  max-width: 120px;
  object-fit: cover;
  border-radius: 6px;
}

.main-account .your-booking-card .booking-card-title {
  font-size: 18px;
  line-height: 24px;
  font-weight: 500;
  margin-bottom: 10px;
  color: #d3b15f;
  padding-right: 30px;
}

.main-account .your-booking-card .booking-card-download {
  cursor: pointer;
  background: radial-gradient(ellipse farthest-corner at right bottom,
      #fedb37 0%,
      #fdb931 8%,
      #9f7928 30%,
      #8a6e2f 40%,
      transparent 80%),
    radial-gradient(ellipse farthest-corner at left top,
      #fff 0%,
      #fdb931 8%,
      #9f7928 25%,
      #8a6e2f 62.5%,
      #5d4a1f 100%);
  width: 50px;
  height: 50px;
  border-radius: 50%;
  color: #fff;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: -10px;
  right: -10px;
}

.main-account .your-booking-card .booking-card-download svg {
  font-size: 22px;
  margin-right: 5px;
  margin-top: 3px;
}

.main-account .your-booking-card .booking-card-row {
  display: flex;
  justify-content: space-between;
  gap: 5px;
  font-size: 12px;
  line-height: 18px;
  width: 100%;
}

.main-account .your-booking-card .booking-card-row:not(:last-child) {
  margin-bottom: 5px;
}

.main-account .your-booking-card .booking-card-row .booking-card-label {
  opacity: 0.7;
  color: #fff;
  min-width: fit-content;
}

.main-account .your-booking-card .booking-card-row .booking-card-value {
  color: #fff;
}

.main-account .account-tab-main .profile-header-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 5px;
  margin-bottom: 20px;
}

.main-account .account-tab-main .profile-img-box {
  display: inline-block;
}

.main-account .account-tab-main .profile-img-box .profile-img {
  position: relative;
  display: flex;
  border: 2px solid #d3b15f;
  border-radius: 50%;
  padding: 5px;
}

.main-account .account-tab-main .profile-img-box img {
  max-width: 80px;
  max-height: 80px;
  min-width: 80px;
  min-height: 80px;
  border-radius: 50%;
  object-fit: cover;
}

.main-account .account-tab-main .profile-img-box svg {
  font-size: 26px;
  background-color: #191510;
  color: #d3b15f;
  border-radius: 50%;
  padding: 3px;
  border: 2px solid #d3b15f;
  position: absolute;
  right: 0;
  bottom: 5px;
  cursor: pointer;
}

.main-account .account-tab-main .form-group input,
.main-account .account-tab-main .form-group .MuiSelect-select {
  padding: 10px 15px;
  font-size: 14px;
  line-height: 20px;
  font-weight: 300;
  color: #fff;
  background-color: transparent;
  width: 100%;
  -webkit-text-fill-color: rgba(255, 255, 255, 1);
}

.main-account .account-tab-main .form-group .MuiSelect-select,
.main-account .account-tab-main .form-group .rdt.birth-input input {
  padding-right: 40px;
}

.main-account .account-tab-main .form-group .divider {
  width: 98%;
  border-color: #130d00 !important;
  padding-top: 20px;
  margin-left: 16px !important;
}

.main-account .account-tab-main .form-group .form-label {
  margin-bottom: 5px;
  color: #fff;
  font-weight: 300;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  gap: 5px;
}

.main-account .account-tab-main .form-group .form-label .edit-label {
  color: #d3b15f;
  display: flex;
  align-items: center;
  gap: 3px;
  cursor: pointer;
  background-color: transparent;
  border: none;
  font-size: 11px;
  font-weight: 400;
  text-transform: none;
}

.main-account .account-tab-main .form-group .form-label .edit-label svg {
  font-size: 12px;
  color: #d3b15f;
}

.main-account .account-tab-main .form-group .add-email-phone {
  padding: 10px 15px;
  font-size: 14px;
  line-height: 20px;
  font-weight: 300;
  color: #fff;
  background-color: #130d00;
  width: 100%;
  border-radius: 100rem;
  border: 1px solid #130d00;
  cursor: pointer;
}

.edit-number-modal {
  backdrop-filter: blur(10px);
  background-color: rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
}

.edit-number-modal .edit-number-modal-inner {
  padding: 25px 20px 20px;
  max-width: 380px;
  width: 100%;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 12px;
  color: #fff;
  overflow: hidden;
  margin: 0 15px;
  max-height: 75vh;
  border: 1px solid #d3b15f;
  display: flex;
}

.edit-number-modal .edit-number-modal-inner .modal-inner {
  width: 100%;
  overflow: auto;
  padding: 0 10px 10px;
  text-align: center;
}

.edit-number-modal .edit-number-modal-inner:is(:focus, :focus-visible) {
  outline: none;
}

.edit-number-modal .edit-number-modal-inner .edit-number-title {
  font-size: 20px;
  line-height: 26px;
  text-align: center;
  margin-bottom: 10px;
}

.edit-number-modal .edit-number-modal-inner .edit-number-content {
  text-align: center;
  margin-bottom: 30px;
  font-weight: 300;
  font-size: 14px;
  line-height: 20px;
}

.edit-number-modal .edit-number-modal-inner .edit-number-form .form-control {
  margin-bottom: 20px;
}

.edit-number-modal .edit-number-modal-inner .edit-number-form input {
  padding: 10px 20px;
  font-size: 13px;
  line-height: 19px;
  font-weight: 300;
  color: #fff;
}

.edit-number-modal .edit-number-modal-inner .edit-number-form fieldset {
  border-radius: 100rem;
  border-width: 1px;
  border-color: #fff;
}

.edit-number-modal .edit-number-modal-inner .edit-number-form .Mui-focused fieldset {
  border-color: #d3b15f;
}

.edit-number-modal .edit-number-modal-inner .edit-number-form .edit-number-btn {
  width: 100%;
}

.edit-number-modal .edit-number-modal-inner .otp-form {
  display: flex;
  justify-content: center;
  gap: 15px;
}

.edit-number-modal .edit-number-modal-inner .otp-form-inner-wrapper {
  margin-bottom: 15px;
}

.edit-number-modal .edit-number-modal-inner .otp-form-inner-wrapper .resend-otp-box {
  margin-top: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  font-weight: 300;
  font-size: 12px;
  line-height: 14px;
}

.edit-number-modal .edit-number-modal-inner .otp-form-inner-wrapper span {
  letter-spacing: 0.5px;
}

.edit-number-modal .edit-number-modal-inner .resend-otp-box .resend-otp-btn {
  cursor: pointer;
  text-decoration: underline;
  background-color: transparent;
  border: 0;
  border-radius: 0;
  color: #fff;
  font-weight: 300;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.5px;
}

.edit-number-modal .edit-number-modal-inner .otp-form input:focus-visible {
  outline: none;
  border-color: #d3b15f;
}

.edit-number-modal .edit-number-modal-inner .otp-form input {
  width: 40px !important;
  padding: 10px;
  font-size: 13px;
  line-height: 19px;
  font-weight: 300;
  color: #fff;
  text-align: center;
  height: 40px;
  background: transparent;
  border-radius: 12px;
  border-width: 1px;
  border-color: #fff;
}

.edit-number-modal .edit-number-modal-inner .otp-form-wrapper .otp-btn {
  width: 100%;
  margin-bottom: 10px;
}

.edit-number-modal .edit-number-modal-inner .otp-form-wrapper .back-btn-box {
  text-align: center;
}

.edit-number-modal .edit-number-modal-inner .otp-form-wrapper .back-btn-box .back-btn {
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  color: #e3e3e3;
  transition: 0.2s all linear;
  position: relative;
}

.edit-number-modal .edit-number-modal-inner .otp-form-wrapper .back-btn-box .back-btn svg {
  rotate: 180deg;
  font-size: 18px;
  transition: 0.15s all linear;
  opacity: 0;
  position: absolute;
  left: 0;
}

.edit-number-modal .edit-number-modal-inner .otp-form-wrapper .back-btn-box .back-btn:hover {
  padding-left: 20px;
}

.edit-number-modal .edit-number-modal-inner .otp-form-wrapper .back-btn-box .back-btn:hover svg {
  opacity: 1;
}

.main-account .account-tab-main .form-group .verify-label p {
  font-size: 10px;
  font-weight: 300;
  line-height: 16px;
  letter-spacing: 0.5px;
  color: #fff;
  border-radius: 100rem;
  padding: 1px 5px;
  background: #006616;
}

.main-account .account-tab-main .form-group .account-tab-title {
  color: #d3b15f;
  margin-bottom: -10px;
}

.main-account .account-tab-main .form-group svg {
  color: #bcbcbc;
}

.main-account .account-tab-main .form-group ::-webkit-calendar-picker-indicator {
  filter: invert(1);
  cursor: pointer;
}

.main-account .account-tab-main .form-group fieldset,
.main-account .account-tab-main .form-group .birth-input input {
  border-radius: 100rem;
  border-width: 1px;
  border-color: #bcbcbc;
  border-style: solid;
}

.main-account .account-tab-main .form-group .birth-input input:focus {
  outline: none;
  border-color: #d3b15f;
}

.main-account .account-tab-main .form-group .Mui-focused fieldset {
  border-color: #d3b15f;
}

.main-account .account-tab-main .form-group .birth-input::after {
  content: "";
  background: url(/src/assets/images/svg/calendar.svg) no-repeat;
  background-size: 100%;
  position: absolute;
  width: 18px;
  height: 18px;
  top: 50%;
  transform: translateY(-50%);
  right: 15px;
  cursor: pointer;
}

.main-account .account-tab-main .form-group .birth-input .rdtPicker {
  width: 100%;
  border-radius: 6px;
  margin-top: 5px;
  background-color: #23211e;
  color: rgb(255 255 255);
  border: 1px solid #130d00;
  font-weight: 300;
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2),
    0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  z-index: 1 !important;
}

.main-account .account-tab-main .form-group .birth-input .rdtPicker th {
  font-weight: 300;
  border-bottom: 0;
}

.main-account .account-tab-main .form-group .birth-input .rdtPicker thead tr:first-of-type th:hover,
.main-account .account-tab-main .form-group .birth-input .rdtPicker td.rdtDay:hover,
.main-account .account-tab-main .form-group .birth-input .rdtPicker td.rdtHour:hover,
.main-account .account-tab-main .form-group .birth-input .rdtPicker td.rdtMinute:hover,
.main-account .account-tab-main .form-group .birth-input .rdtPicker td.rdtSecond:hover,
.main-account .account-tab-main .form-group .birth-input .rdtPicker .rdtTimeToggle:hover,
.main-account .account-tab-main .form-group .birth-input .rdtPicker .rdtCounter .rdtBtn:hover,
.main-account .account-tab-main .form-group .birth-input .rdtPicker .rdtTime .rdtSwitch:hover,
.main-account .account-tab-main .form-group .birth-input .rdtPicker td.rdtMonth:hover,
.main-account .account-tab-main .form-group .birth-input .rdtPicker td.rdtYear:hover {
  background: #130d00;
  border-radius: 100rem;
  cursor: pointer;
}

.main-account .account-tab-main .form-group .birth-input .rdtPicker td.rdtActive,
.main-account .account-tab-main .form-group .birth-input .rdtPicker td.rdtActive:hover {
  background: #d3b15f;
  border-radius: 100rem;
  color: #191510;
  font-weight: 400;
}

.main-account .account-tab-main .form-group .birth-input .rdtPicker td.rdtToday:before {
  border-bottom-color: #d3b15f;
}

.main-account .account-tab-main .form-group .form-submit {
  text-align: end;
  margin-top: 10px;
}

.main-account .profile-img-box .profile-img .profile-input {
  display: none;
}

.main-account .profile-img-box .profile-img .profile-label {
  display: block;
}

.account-tab-wishlist .wishlist-main-wrapper {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 10px;
}