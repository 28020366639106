.main-hero-slider {
    background-color: #23211e;
}

.hero-slider-img {
    display: flex;
}

.hero-slider-img img {
    width: 100%;
    object-fit: unset;
    object-position: top;
    height: 100%;
}

.main-showing-part,
.main-upcoming-part {
    background-color: #000;
}

.main-membership-plan {
    background: #000;
    padding: 30px 0;
}

.main-recent-releases-part {
    background: #000;
}

.membership-grid-main {
    max-width: 1000px;
    margin: 0 auto;
}

.membership-right-box {
    background: #000;
    display: flex;
    align-items: center;
}

.member-right-details {
    padding: 30px;
}

.membership-btns a {
    color: #fff;
    display: flex;
}

.membership-grid-main .member-img {
    width: 100%;
    object-fit: cover;
    height: 100%;
}

.member-right-details h4 {
    font-size: 22px;
    color: #fff;
    line-height: 36px;
    margin-bottom: 20px;
}

.member-right-details p {
    font-size: 12px;
    line-height: 23px;
    color: #bcbcbc;
    margin-bottom: 15px;
}

.member-right-details .membership-btns {
    margin-top: 30px;
    display: flex;
    align-items: center;
    gap: 20px;
}
