.main-career .main-career-wrapper {
  padding: 60px 0;
}

.main-career .career-img {
  text-align: center;
}

.main-career .career-img img {
  height: 570px;
  width: 100%;
  object-fit: cover;
  border-radius: 12px;
}

.main-career .career-form {
  padding: 20px 30px 30px;
  background-color: #23211e;
  border-radius: 12px;
  position: relative;
}

.main-career .career-form .career-form-title {
  font-size: 24px;
  line-height: 30px;
  font-weight: 500;
  margin-bottom: 15px;
  color: #d3b15f;
}

.main-career .career-form input,
.main-career .career-form textarea {
  width: 100%;
  padding: 10px;
  font-size: 14px;
  line-height: 1;
  color: #fff;
  font-weight: 300;
}

.main-career .career-form .form-label {
  color: #fff;
  font-weight: 300;
  margin: 0 0 5px;
}

.main-career .career-form .form-control fieldset {
  border-color: #bcbcbc;
  border-width: 1px;
  border-radius: 100rem;
}

.main-career .career-form .form-control .Mui-focused fieldset {
  border: 1px solid #d3b15f;
}

.main-career .career-form textarea {
  border-radius: 12px;
  border-color: #fff;
  border-width: 1px;
  background-color: transparent;
  resize: vertical;
  font-family: inherit;
  font-weight: 400;
}

.main-career .career-form textarea:focus {
  outline: none;
  border: 1px solid #d3b15f;
}

.main-career .career-form .file-upload-box {
  border: 1px solid #fff;
  border-radius: 100rem;
  position: relative;
  display: flex;
  align-items: center;
}

.main-career .career-form .file-upload-box input {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}

.main-career .career-form .file-upload-box label {
  color: #bcbcbc;
  padding: 10px;
}

.main-career .career-form .form-action {
  text-align: right;
}

.main-career .career-form .form-action button {
  width: 100%;
  max-width: 150px;
}